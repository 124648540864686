// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import "./variables";


// Create the theme object (a Sass map containing all of the palettes).
$upt-frontend-theme: mat-light-theme($upt-frontend-primary, $upt-frontend-accent, $upt-frontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($upt-frontend-theme);


.mat-green {
    background-color: #3d981f;
    color: white;
}
