@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../assets/fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('../assets/fonts/roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../assets/fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Thin.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-ThinItalic.eot');
    src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url('../assets/fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('../assets/fonts/roboto/Roboto-ThinItalic.eot');
    src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url('../assets/fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-ThinItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'), url('../assets/fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../assets/fonts/roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'), url('../assets/fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-LightItalic.eot');
    src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('../assets/fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('../assets/fonts/roboto/Roboto-LightItalic.eot');
    src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('../assets/fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-LightItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Regular.eot');
    src: local('Roboto Regular'), local('Roboto-Regular'), url('../assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../assets/fonts/roboto/Roboto-Regular.eot');
    src: local('Roboto Regular'), local('Roboto-Regular'), url('../assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-RegularItalic.eot');
    src: local('Roboto RegularItalic'), local('Roboto-RegularItalic'), url('../assets/fonts/roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-RegularItalic';
    src: url('../assets/fonts/roboto/Roboto-RegularItalic.eot');
    src: local('Roboto RegularItalic'), local('Roboto-RegularItalic'), url('../assets/fonts/roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-RegularItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../assets/fonts/roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-MediumItalic.eot');
    src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('../assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('../assets/fonts/roboto/Roboto-MediumItalic.eot');
    src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('../assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../assets/fonts/roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-BoldItalic.eot');
    src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url('../assets/fonts/roboto/Roboto-BoldItalic.eot');
    src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'), url('../assets/fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../assets/fonts/roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'), url('../assets/fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-Black.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto/Roboto-BlackItalic.eot');
    src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url('../assets/fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('../assets/fonts/roboto/Roboto-BlackItalic.eot');
    src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url('../assets/fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto/Roboto-BlackItalic.woff2') format('woff2');
}
