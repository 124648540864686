/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'styles/fonts';

upt-mobility-documents {
  width: 100%;
}

.forced-link {
  cursor: pointer;
}

.text-gray {
  color: gray;
}

.full-width {
  width: 100%;
}

.full-width-for-ie {
  width: 100%;
}

.form-page-with-action-bar {
  margin-bottom: 100px;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
}

*:focus {
  outline: none !important;
}
/* background of the scrollbar except button or resizer */


::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
  border-radius: 4px;
}


// Import theming functions
.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.75) !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 12001 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.question-icon {
  margin-left: 5px;
  cursor: pointer;
  font-size: 21px;
}

// table
.paginator {
  .paginator-current {
    margin-left: auto;
  }
}

.progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .progressbar-value {
    background-color: #607d8b;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  p {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.calendar .datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.paginator .dropdown {
  margin-left: 0.5rem;
  height: 2.7rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0rem !important;
}

.p-dialog .p-dialog-content {
  padding: 0 1.25rem 0.5rem;
}

.p-button {
  color: #fff;
  background: #00135f;
}

.p-button:enabled:focus {
  background: #00135f;
}
.p-button:enabled:active,
.p-button:enabled:hover {
  color: #fff;
  border-color: transparent;
}
.p-button:enabled:hover {
  background: #00135f;
}

.mat-menu-panel {
  min-height: auto !important;
  height: auto !important;
}

.ck.ck-editor__editable_inline {
  max-height: 300px !important;
}

.p-dropdown {
  height: 37px !important;
  align-items: center !important;
}

.p-inputtext {
  height: 37px !important;
  align-items: center !important;
}

.p-dropdown-label {
  padding-top: 10px;
}

.p-paginator {
  button {
    width: 2rem !important;
    height: 2rem !important;
    min-width: 2rem !important;
    min-height: 2rem !important;
  }
}

th {
  font-size: 14px !important;
}
td {
  font-size: 14px !important;
}

.col-2 {
  padding: 0.35rem;
}

.col-4 {
  padding: 0.35rem;
}

.col-5 {
  padding: 0.35rem;
}

.col-6 {
  padding: 0.35rem;
}

.col-8 {
  padding: 0.35rem;
}

.col-10 {
  padding: 0.35rem;
}

.col-12 {
  padding: 0.1rem;
}

.col-auto {
  padding: 0.25rem;
}

.p-toast-top-right {
  z-index: 2000 !important;
}

.p-toast-message-content {
  align-items: center !important;
}

.p-avatar.p-avatar-lg {
  width: 2.35rem;
  height: 2.35rem;
}

.p-overlaypanel-content {
  min-width: 450px;
  max-width: 450px;
  // padding: 0.75rem !important;
  font-size: 14px !important;
}

.mat-menu-panel {
  max-width: 450px !important;
}

.mat-select-panel {
  max-width: 450px !important;
}

.p-toast {
  position: fixed;
  width: 30rem !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.75rem !important;
}

td {
  word-break: normal !important;
}

button {
  text-transform: uppercase !important;
}

mat-checkbox {
  span {
    word-break: normal !important;
    white-space: pre-line !important;
    font-size: 14px !important;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1em !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1650px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  display: table-cell !important;
}

.p-datatable .p-datatable-thead > tr > th {
  display: table-cell !important;
}

.p-datatable .p-datatable-thead > tr {
  display: table-row !important;
}

.p-datatable .p-datatable-tbody > tr {
  display: table-row !important;
}

.p-datatable-scrollable .p-datatable-table,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot,
.p-datatable-scrollable .p-datatable-thead {
  display: revert !important;
}

mat-card {
  .p-datatable-flex-scrollable .p-datatable-wrapper {
    padding-top: 1px !important;
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}

.p-datatable-scrollable .p-datatable-thead {
  top: -1px !important;
}

.p-datatable .p-sortable-column:focus {
  background-color: white !important;
}

.p-datatable {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .paginator {
    padding: 0.2rem;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }
}

/* Responsive */
.p-datatable-tbody > tr > td .column-title {
  display: none;
}

.p-datatable .p-paginator-bottom,
.p-datatable .p-paginator-top {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 0 0 1px 0 !important;
}

.p-datatable .p-datatable-header {
  text-align: left;
  font-size: 1.25rem;
}

.p-datatable-flex-scrollable {
  max-height: 100% !important;
  height: auto !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: #00135f;
}

.p-datatable .p-datatable-footer {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 1px 0 1px 0;
}

.p-datatable .p-paginator-bottom,
.p-datatable .p-paginator-top {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 1px 0 1px 0;
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  padding: 0px;
}

@media screen and (max-width: 960px) {
  .p-datatable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--layer-2);

      > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }

        .progressbar {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.ngx-file-drop__drop-zone {
  height: 60px !important;
  border: 2px dotted #00135f !important;
}

.ngx-file-drop__content {
  height: 60px !important;
  color: #00135f !important;
  font-size: 18px !important;
}

// a {
//   color: #00135f !important;
// }

.primary-color {
  color: #00135f;
}

.mat-select-search-panel {
  min-width: 571px !important;
}

.p-overlaypanel-content {
  max-width: 650px !important;
  min-width: 550px !important;
}

.mat-mini-fab {
  height: 35px !important;
  width: 35px !important;
  .material-icons {
    font-size: 21px !important;
    margin-top: -4px !important;
  }
}

.p-datatable-hoverable-rows .p-selectable-row {
  td {
    cursor: pointer !important;
  }
}

.mat-select-no-options{
  padding: 12px 0px 0px 16px;
  color: gray;
}